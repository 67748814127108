.input-range {
  margin-top: 1rem;

  &__track {
    height: 0.35rem;
    background: #e5ebf0;

    &--active {
      background: #3166f4;
    }
  }

  &__slider {
    background: #3166f4;
    border: 1px solid #3166f4;
  }

  &__label {
    font-family: 'Poppins', sans-serif;
    color: #102740;
    font-size: 0.875rem;

    &--value {
      top: -2rem;
    }

    &--min,
    &--max {
      display: none;
    }
  }
}
