.react-tel-input {
  --phone-input-border-color: #cfd4d9;

  .form-control {
    width: 100% !important;
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
    height: unset !important;
    border-radius: 0.625rem !important;
    padding-left: 4rem !important;
    font-size: 0.875rem !important;
    line-height: 1.3125rem !important;
    color: #102740 !important;
    border-color: var(--phone-input-border-color) !important;

    &:focus,
    &:focus + .flag-dropdown {
      --phone-input-border-color: #3166f4;
    }
  }

  &--error {
    --phone-input-border-color: #ec4c4c;
  }

  .flag-dropdown {
    border-top-left-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
    width: 3.125rem !important;
    background-color: #ffffff !important;
    border-color: var(--phone-input-border-color) !important;

    .selected-flag {
      width: 100% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding: 0 !important;
      background: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    .country-list {
      width: 25rem !important;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
      border-radius: 0.625rem;
    }
  }
}
