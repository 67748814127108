@font-face {
  font-family: 'Michelin Black';
  src: url('/fonts/michelin/Michelin-Black.otf');
}
@font-face {
  font-family: 'Michelin Bold';
  src: url('/fonts/michelin/Michelin-Bold.otf');
}
@font-face {
  font-family: 'Michelin Light';
  src: url('/fonts/michelin/Michelin-Light.otf');
}
@font-face {
  font-family: 'Michelin Regular';
  src: url('/fonts/michelin/Michelin-Regular.otf');
}
@font-face {
  font-family: 'Michelin Semibold';
  src: url('/fonts/michelin/Michelin-SemiBold.otf');
}
@font-face {
  font-family: 'Volkswagen';
  font-weight: 900;
  src: url('/fonts/volkswagen/Volkswagen-Regular.otf');
}
@font-face {
  font-family: 'Biennale Bold';
  src: url('/fonts/Biennale-Bold.otf');
}
@font-face {
  font-family: 'Biennale Regular';
  src: url('/fonts/Biennale-Regular.otf');
}
@font-face {
  font-family: 'Ridley Grotesk Bold';
  src: url('/fonts/Ridley-Grotesk-Bold.otf');
}
@font-face {
  font-family: 'Ridley Grotesk Medium';
  src: url('/fonts/Ridley-Grotesk-Medium.otf');
}
@font-face {
  font-family: 'OpenSans Bold';
  src: url('/fonts/opensans/OpenSans-Bold.ttf');
}
@font-face {
  font-family: 'OpenSans BoldItalic';
  src: url('/fonts/opensans/OpenSans-BoldItalic.ttf');
}
@font-face {
  font-family: 'OpenSans ExtraBold';
  src: url('/fonts/opensans/OpenSans-ExtraBold.ttf');
}
@font-face {
  font-family: 'OpenSans ExtraBoldItalic';
  src: url('/fonts/opensans/OpenSans-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'OpenSans Italic';
  src: url('/fonts/opensans/OpenSans-Italic.ttf');
}
@font-face {
  font-family: 'OpenSans Light';
  src: url('/fonts/opensans/OpenSans-Light.ttf');
}
@font-face {
  font-family: 'OpenSans LightItalic';
  src: url('/fonts/opensans/OpenSans-LightItalic.ttf');
}
@font-face {
  font-family: 'OpenSans Regular';
  src: url('/fonts/opensans/OpenSans-Regular.ttf');
}
@font-face {
  font-family: 'OpenSans Semibold';
  src: url('/fonts/opensans/OpenSans-Semibold.ttf');
}
@font-face {
  font-family: 'OpenSans SemiboldItalic';
  src: url('/fonts/opensans/OpenSans-SemiboldItalic.ttf');
}
@font-face {
  font-family: 'Palanquin Bold';
  src: url('/fonts/palanquin/Palanquin-Bold.ttf');
}
@font-face {
  font-family: 'Palanquin ExtraLight';
  src: url('/fonts/palanquin/Palanquin-ExtraLight.ttf');
}
@font-face {
  font-family: 'Palanquin Light';
  src: url('/fonts/palanquin/Palanquin-Light.ttf');
}
@font-face {
  font-family: 'Palanquin Medium';
  src: url('/fonts/palanquin/Palanquin-Medium.ttf');
}
@font-face {
  font-family: 'Palanquin Regular';
  src: url('/fonts/palanquin/Palanquin-Regular.ttf');
}
@font-face {
  font-family: 'Palanquin SemiBold';
  src: url('/fonts/palanquin/Palanquin-SemiBold.ttf');
}
@font-face {
  font-family: 'Palanquin Thin';
  src: url('/fonts/palanquin/Palanquin-Thin.ttf');
}
