.react-select {
  &__control {
    border-radius: 0.625rem !important;
    min-height: 3.125rem !important;
    border-color: #cfd4d9 !important;

    &--is-focused {
      border-color: #3166f4 !important;
      box-shadow: 0 0 0 1px #3166f4 !important;
    }
  }

  &__value-container {
    padding-left: 0.875rem !important;
  }

  &__placeholder {
    font-size: 0.875rem !important;
    color: #9fa9b3 !important;
  }

  &__input {
    font-size: 0.875rem !important;
    color: #102740 !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  &__single-value {
    color: #102740 !important;
    font-size: 0.875rem !important;
  }

  &__multi-value {
    background-color: #f0f2f3 !important;
    border-radius: 0.375rem !important;

    &__label {
      color: #102740 !important;
      font-size: 0.875rem !important;
    }
  }
}

.error {
  .react-select {
    &__control {
      border-color: #ec4c4c !important;

      &--is-focused {
        border-color: #3166f4 !important;
        box-shadow: 0 0 0 1px #3166f4 !important;
      }
    }
  }
}
