@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  html,
  body,
  #__next {
    @apply h-full;
    @apply min-h-full;
    @apply bg-gray-100;
  }

  html,
  body {
    font-family: 'Poppins', sans-serif;
  }
}

@import './component/fabric';
@import './component/fonts';
@import './component/input-range';
@import './component/phone-input';
@import './component/react-select';
@import './component/previews.scss';
